/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-railroad': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.303 6.584h1.064c.592 0 .936.334.936.844a.79.79 0 01-.485.748l.536 1.074h-.59l-.467-.994h-.473v.994h-.521zm.521.414v.861h.46c.292 0 .474-.14.474-.421 0-.286-.188-.44-.467-.44zm-8.771-.414h1.064c.592 0 .936.334.936.844 0 .39-.242.654-.485.748l.536 1.074h-.59l-.467-.994h-.473v.994h-.521zm.521.414v.861h.46c.292 0 .474-.14.474-.421 0-.286-.188-.44-.467-.44z"/><path pid="1" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.48 1.48 0 010-2.098zm1.4.7a.495.495 0 00-.7 0L4.923 3.861 8 6.939l3.078-3.077L8.35 1.134zm3.788 3.788L9.061 8l3.077 3.078 2.728-2.728a.495.495 0 000-.7zm-1.06 7.215L8 9.061l-3.077 3.077 2.727 2.728a.495.495 0 00.7 0zm-7.216-1.06L6.939 8 3.862 4.923 1.134 7.65a.495.495 0 000 .7z"/>',
    },
});
